<template>
    <div class="main-block">
      <div class="d-flex justify-content-center align-items-center" v-if="loading" style="height: 90vh">
        <b-spinner variant="primary" label="Text Centered" />
      </div>
      <div v-else>
        <b-card title="Статистика водителей, которые успешно зарегистрированы">
          <app-echart-bar :option-data="driversAnalytic" />
        </b-card>
        <b-card title="Статистика водителей, которые не завершили процесс регистрации">
          <app-echart-bar :option-data="driverProfilesAnalytic" />
        </b-card>
      </div>
    </div>
  </template>
  
  <script>
  import { BCard } from 'bootstrap-vue';
  import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue';
  
  export default {
    components: {
      BCard,
      AppEchartBar,
    },
    data() {
      return {
        loading: false,
        driversAnalytic: {},
        driverProfilesAnalytic: {},
      };
    },
    mounted() {
      this.getDataDriversAnalytic();
    },
    methods: {
      async drivers() {
        try {
          const res = await this.$http.get('dashboard/drivers');
          this.driversAnalytic = {
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true,
            },
            xAxis: [
              {
                type: 'category',
                data: res.data.days,
              },
            ],
            yAxis: [
              {
                type: 'value',
              },
            ],
            series: [
              {
                name: 'Зарегистрированные водители',
                type: 'bar',
                data: res.data.counts,
              },
            ],
          };
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      },
      async driverProfiles() {
        try {
          const res = await this.$http.get('dashboard/driver-profiles');
          this.driverProfilesAnalytic = {
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true,
            },
            xAxis: [
              {
                type: 'category',
                data: res.data.days,
              },
            ],
            yAxis: [
              {
                type: 'value',
              },
            ],
            series: [
              {
                name: 'Незарегистрированные водители',
                type: 'bar',
                data: res.data.counts,
              },
            ],
          };
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      },
      getDataDriversAnalytic() {
        this.loading = true;
        this.drivers();
        this.driverProfiles();
      },
    },
    computed: {
      fetchingNewData() {
        return this.$store.state.refresh.fetchingNewData;
      },
    },
    watch: {
      fetchingNewData(val) {
        if (val) {
          this.loading = true;
          this.getDataDriversAnalytic();
        }
      },
    },
  };
  </script>
  
  <style>
  .main-block {
    overflow: scroll;
    height: calc(100vh - 92px);
  }
  </style>
  